import endpoint from 'endpoints/api';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import {IApiUserLog, ILogEntry, IUserLogResult} from "../models/userLogs";
import {URLQueryData} from "../builders/url";

export class UserLogsService extends BaseService {
    private _repository = new Repository(endpoint)

    queries = {
        getLogs: (discussionId: string, personId: string) => this._repository.query<void, IApiUserLog>(`v3/discussions/${discussionId}/participants/${personId}/userlogs`),
        createLogs: (discussionId?: string) => this._repository.postQuery<void, IUserLogResult>(`v3/discussions/${discussionId}/userlogs`),
        updateLogs: (userLogId: string) => this._repository.updateQuery<ILogEntry[], IUserLogResult>(`v3/userlogs/${userLogId}`),
        deleteLogs: (userLogId: string) => this._repository.removeQuery<IUserLogResult>(`v3/userlogs/${userLogId}`)
    }

    async getLogs(discussionId: string, personId: string, args: URLQueryData) {
        return await this.queries.getLogs(discussionId, personId).addArgs(args).toArray();
    }

    async createLogs(discussionId?: string) {
        return await this.queries.createLogs(discussionId).add();
    }

    async updateLogs(userLogId: string, logEntries: ILogEntry[]) {
        return await this.queries.updateLogs(userLogId).update(logEntries);
    }

    async deleteLogs(userLogId: string) {
        return await this.queries.deleteLogs(userLogId).remove();
    }
}